import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/visby-university-updates/hero"
import SubForm from "./screens/visby-university-updates/form"

const ThankYou = () => {
  return (
    <React.Fragment>
      <Hero />
      <SubForm />
    </React.Fragment>
  )
}

export default ThankYou

export const Head = () => (
  <Seo
    title="Visby University Updates"
    description="Subscribe to the Visby Medical updates list."
    recaptcha
  />
)
